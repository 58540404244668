/**
 * Collection of enums for all pages and routes.
 * - root:            trailing slash not required because root domain
 * - all other pages: add trailing slash for all pages except root domain
 */
import { isProDomain, subdomainName } from "../util/config";

export const seoTitlePostfix = ` - GameDiscoverCo ${subdomainName}`;
const proPrefix = isProDomain ? "" : "/pro";

export const routeInternalPro = {
  home: {
    caption: "Home",
    to: `${proPrefix}/`,
  },
  search: {
    caption: "Search",
    to: `${proPrefix}/search/`,
  },
  browse: {
    caption: "Browse",
    to: `${proPrefix}/browse/`,
  },
  details: {
    caption: "Details",
    to: `${proPrefix}/details/`,
  },
  detailsAffinityExplorer: {
    caption: "Affinity Explorer",
    to: `${proPrefix}/details/:masterid/affinity-explorer/`,
  },
  allFormats: {
    caption: "All Formats",
    to: `${proPrefix}/`,
  },
  pc: {
    caption: "PC (Steam)",
    to: `${proPrefix}/platforms/steam/`,
  },
  xbox: {
    caption: "Xbox",
    to: `${proPrefix}/platforms/xbox/`,
  },
  playstation: {
    caption: "PlayStation",
    to: `${proPrefix}/platforms/playstation/`,
  },
  publisherDeveloper: {
    caption: "Publisher / Developer",
    to: `${proPrefix}/publisher-developer/`,
  },
  countryExplorer: {
    caption: "Country Explorer",
    to: `${proPrefix}/country-explorer/`,
  },
  tagExplorer: {
    caption: "Tag Explorer",
    to: `${proPrefix}/tag-explorer/`,
  },
  tag: {
    caption: "Tag",
    to: `${proPrefix}/tag-explorer/:tag`,
  },
  dauRankings: {
    caption: "DAU Rankings",
    to: `${proPrefix}/dau-mau-rankings/dau/`,
  },
  mauRankings: {
    caption: "MAU Rankings",
    to: `${proPrefix}/dau-mau-rankings/mau/`,
  },
  mostHypedGames: {
    caption: "Most Hyped Games",
    to: `${proPrefix}/most-hyped-games/`,
  },
  topGenresByHype: {
    caption: "Top Genres By Hype",
    to: `${proPrefix}/top-genres-by-hype/`,
  },
  revenuePredictor: {
    caption: "Revenue Predictor",
    to: `${proPrefix}/revenue-predictor/`,
  },
  affinityExplorer: {
    caption: "Affinity Explorer",
    to: `${proPrefix}/affinity-explorer/`,
  },
  insight: {
    caption: "Pro Insight",
    to: `${proPrefix}/pro-insight/`,
  },
  switch: {
    caption: "Switch",
    to: `${proPrefix}/switch/`,
  },
  settings: {
    caption: "Settings",
    to: `${proPrefix}/settings/`,
  },
  cmsPage: {
    caption: "CMS Page",
    to: `${proPrefix}/p/:slug/`,
  },
  adminCms: {
    caption: "Admin - CMS",
    to: "/cms/",
  },
};

export const routeInternal = {
  /* --- common routes --- */
  home: {
    caption: "Home",
    to: "/",
  },
  login: {
    caption: "Login",
    to: "/login/",
  },
  passwordReset: {
    caption: "Password Reset",
    to: "/login/password-reset/",
  },
  pitchdecks: {
    caption: "Pitch Decks",
    to: "/pitch-decks/",
  },
  changelog: {
    caption: "Changelog",
    to: "/changelog/",
  },

  /* --- other signed in routes --- */
  ebooks: {
    caption: "EBook Downloads",
    to: "/ebooks/",
  },
  publisherDeveloper: {
    caption: "Publisher / Developer",
    to: "/publisher-developer/",
  },
  tagExplorer: {
    caption: "Tag Explorer",
    to: "/tag-explorer/",
  },
  tag: {
    caption: "Tag",
    to: (tag: string) => `/tag-explorer/${tag}/`,
  },

  /* --- API routes --- */
  api: {
    caption: "GameDiscoverCo Plus API",
    to: "/api/",
  },
  apiAccess: {
    caption: "Access your GameDiscoverCo Plus API Token",
    to: "/api/access/",
  },

  /* --- admin routes --- */
  admin: {
    caption: "Admin",
    to: "/admin/",
  },
  adminCron: {
    caption: "Admin - Cron",
    to: "/admin/cron/",
  },
  adminSearch: {
    caption: "Admin - Search",
    to: "/admin/search/",
  },
  adminUsers: {
    caption: "Admin - Users",
    to: "/admin/users/",
  },

  /* --- user routes --- */
  compare: {
    caption: "Compare",
    to: "/compare/",
  },
  forecast: {
    caption: "Forecast",
    to: "/forecast/",
  },
  favoriteApps: {
    caption: "Favorite Apps",
    to: "/favorite-apps/",
  },
  studiosToWatch: {
    caption: "Studios to Watch",
    to: "/studios-to-watch/",
  },
  revenuePredictor: {
    caption: "Revenue Predictor",
    to: "/revenue-predictor/",
  },
  settings: {
    caption: "Settings",
    to: "/settings/",
  },
  browse: {
    caption: "Browse",
    to: "/browse/",
  },

  /* --- app details routes --- */

  details: {
    caption: "Game Details",
    to: "/details/",
  },
  detailsRevenuePredictor: {
    caption: "Revenue Predictor",
    to: "/details/:appid/revenue-predictor/",
  },
  detailsAffinityExplorer: {
    caption: "Affinity Explorer",
    to: "/details/:appid/affinity-explorer/",
  },
  detailsHypeAnnotator: {
    caption: "Hype Annotator",
    to: "/details/:appid/hype-annotator/",
  },

  /* --- STEAM CHARTS --- */
  companyHitList: {
    caption: "Steam Company Hit List",
    to: "/company-hit-list/",
  },
  countryExplorer: {
    caption: "Country Explorer",
    to: "/country-explorer/",
  },
  followers: {
    caption: "Steam Unreleased Games: Top # of Followers Chart",
    to: "/followers/",
  },
  followerVelocity: {
    caption: "Top Games (Follower Velocity)",
    to: "/follower-velocity/",
  },
  heatmap: {
    caption: "Steam Release Heatmap",
    to: "/steam-release-heatmap/",
  },
  hype: {
    caption: "Steam Hype Chart",
    to: "/hype/",
  },
  hypeAnnotator: {
    caption: "Hype Annotator",
    to: "/hype-annotator/",
  },
  hypeConversionSignificantPostRelease: {
    caption: "Steam Recent Post-Release Chart for Significant Games",
    to: "/hype-conversion-significant-post-release/",
  },
  hypeConversionTagRanking: {
    caption: "Steam Hype Conversion Tag Ranking",
    to: "/hype-conversion-tag-ranking/",
  },
  mostReviewed: {
    caption: "Monthly Most-Reviewed Steam Games",
    to: "/most-reviewed/",
  },
  nocturne: {
    caption: "Steam Nocturne Chart",
    to: "/nocturne/",
  },
  postReleaseMonthly: {
    caption: "Steam Monthly Post-Release Chart (New Games)",
    to: "/post-release-monthly/",
  },
  postReleaseWeekly: {
    caption: "Steam Weekly Post-Release Chart (New Games)",
    to: "/post-release-weekly/",
  },
  monthlyAll: {
    caption: "Steam Monthly Chart (All Games)",
    to: "/monthly-all/",
  },
  weeklyAll: {
    caption: "Steam Weekly Chart (All Games)",
    to: "/weekly-all/",
  },
  ccuRankings: {
    caption: "CCU Rankings",
    to: "/ccu-rankings/",
  },
  affinityExplorer: {
    caption: "Affinity Explorer",
    to: "/affinity-explorer/",
  },
  saleEvents: {
    caption: "Steam Sale Events",
    to: "/sale-events/",
  },
  saleEventsDetails: {
    caption: "Steam Sale Event Details",
    to: "/sale-events/:eventId/",
  },
  salesExplorer: {
    caption: "Sales Explorer",
    to: "/sales-explorer/",
  },
  tagRanking: {
    caption: "Steam Pre-Release Tag Ranking",
    to: "/pre-release-tag-ranking/",
  },
  trending: {
    caption: "Steam Trending Pre-Release Chart",
    to: "/trending/:page/",
  },
  trendingTagRanking: {
    caption: "Steam Trending Tag Ranking",
    to: "/trending-tag-ranking/",
  },
  weeklyHypeCharts: {
    caption: "Steam Weekly Hype Charts",
    to: "/weekly-hype-charts/",
  },
  wishlists: {
    caption: "Steam Unreleased Games: Top Wishlists Chart",
    to: "/wishlists/",
  },
  postReleaseCharts: {
    caption: "Post-Release Charts",
    to: "/post-release-charts/",
  },
  preReleaseCharts: {
    caption: "Pre-Release Charts",
    to: "/pre-release-charts/",
  },

  /* --- OTHER CHARTS --- */
  otherCharts: {
    caption: "Other Charts",
    to: "/other-charts/",
  },
  switch: {
    caption: "U.S. Switch 'Recent/All' Top Download Charts",
    to: "/switch-top-downloads/",
  },
  xbox: {
    caption: "U.S. Xbox 'Recent/All' Top Download Charts",
    to: "/xbox-recent-downloads/",
  },
  playstation: {
    caption: "U.S. PlayStation 'Recent/All' Top Download Charts",
    to: "/playstation-top-downloads/",
  },
  epic: {
    // -> linked to at epic top sellers bottom component
    caption: "Archived Epic ranking data from May 2021 to June 2022",
    to: "/epic-games-store-top-games/",
  },
  epicTopSellers: {
    caption: "Epic Games Store Top Sellers",
    to: "/epic-top-sellers/",
  },
  meta: {
    caption: "Global Meta Quest VR 'Recent/All' Top Charts (By Star Rating)",
    to: "/meta-quest-vr-top-games/",
  },
  apple: {
    caption: "U.S. Apple Arcade Top Games Chart (By Review Count)",
    to: "/apple-arcade-top-games/",
  },
  fortniteTopMaps: {
    caption: "Fortnite Top Maps/Experiences (By CCU)",
    to: "/fortnite-top-maps/",
  },
} satisfies Record<
  string,
  { caption: string; to: string | ((...args: never[]) => string) }
>;

export const linkTo = {
  apiDocs: "https://documenter.getpostman.com/view/4312055/UVRHi3Y4",
  discord: "https://discord.gg/zvP7My2Q3e",
  email: "simon@gamediscover.co",
  contactEmail: "contact@gamediscover.co",
  companyDomain: "https://gamediscover.co",
  newsletterDomain: "https://newsletter.gamediscover.co",
  twitter: "https://twitter.com/gamediscoverco",
};
export const a_rel = {
  default: "noopener noreferrer nofollow",
  noreferrer: "noopener noreferrer",
  nofollow: "noopener nofollow",
  endorse: "noopener",
} as const;
